import { BaseConnection, NclMessageType, ReceiveData } from "../common/communication";
import { CSClientFile, DeviceConnectorDataRequest, TDeviceConnectResult } from "../common/communication.base";

export default class DeviceConnector {
  private files: Map<string, string>;
  private connectionString: string;
  private connection: BaseConnection;
  private requestCounter: number;

  public constructor() {
    this.files = new Map<string, string>();
    this.requestCounter = 0;
  }

  public setFile(data: CSClientFile) {
    if (data) {
      this.files.set(data.FileName, data.Url);
    }
  }

  public get RequestCounter(): number {
    return this.requestCounter;
  }

  public connect(connectionString: string): Promise<DeviceConnectorDataRequest> {
    this.connectionString = connectionString;
    return new Promise((resolve, reject) => {
      this.requestCounter++;
      this.getConnection()
        .connect()
        .then((res) => {
          resolve({ ResultType: TDeviceConnectResult.dcrOK, ResultMessage: undefined } as DeviceConnectorDataRequest);
          this.requestCounter--;
        })
        .catch((reason) => {
          reject(`Failed to connect to device connector at ${connectionString}`);
          this.requestCounter--;
        });
    });
  }

  public disconnect(): Promise<DeviceConnectorDataRequest> {
    if (this.getConnection().isConnected) {
      return new Promise<DeviceConnectorDataRequest>((resolve, reject) => {
        this.requestCounter++;
        this.getConnection()
          .disconnect()
          .then((val) => {
            resolve({ ResultType: TDeviceConnectResult.dcrOK, ResultMessage: undefined } as DeviceConnectorDataRequest);
            this.requestCounter--;
          })
          .catch((reason) => {
            reject({ ResultType: TDeviceConnectResult.dcrError, ResultMessage: reason } as DeviceConnectorDataRequest);
            this.requestCounter--;
          });
      });
    }

    return Promise.reject<DeviceConnectorDataRequest>({
      ResultType: TDeviceConnectResult.dcrError,
      ResultMessage: "Connection was not connected",
    } as DeviceConnectorDataRequest);
  }

  public get isConnected(): boolean {
    return this.connectionString && this.getConnection().isConnected;
  }

  private getConnection(): BaseConnection {
    if (this.connection == null) {
      this.connection = new BaseConnection(new URL("http://" + this.connectionString));
    }

    return this.connection;
  }

  public sendRequest(request: string): Promise<DeviceConnectorDataRequest> {
    let rq = request;
    if (this.isConnected) {
      if (this.files.size > 0) {
        rq = this.replaceFile(JSON.parse(rq));
      }
      if (this.connection.sendText(rq)) {
        return new Promise<DeviceConnectorDataRequest>((resolve, reject) => {
          this.requestCounter++;
          this.connection
            .receive()
            .then((result: ReceiveData) => {
              if (result && result.message && result.message.messageType == NclMessageType.nmsgOK && result.message.hasOwnProperty("msgType")) {
                const o: any = result.message;
                const msgType = o.msgType;
                if (msgType !== undefined) {
                  resolve({
                    ResultType: msgType,
                    ResultMessage: result.message.json && result.message.json.length > 0 ? result.message.json : undefined,
                  } as DeviceConnectorDataRequest);
                  this.requestCounter--;
                  return;
                }
              }
              reject({ ResultType: TDeviceConnectResult.dcrError, ResultMessage: `Invalid message type: ${result}` } as DeviceConnectorDataRequest);
              this.requestCounter--;
            })
            .catch((reason) => {
              if (typeof reason === "string") reject(reason);
              else if (reason instanceof CloseEvent) {
                reject(`Conection with DeviceConnector closed. Error code:${reason.code}, error: ${reason.reason}.`);
              } else {
                reject("Unknown error in DeviceConnector");
              }
              this.requestCounter--;
            });
        });
      } else {
        return Promise.reject<DeviceConnectorDataRequest>({
          ResultType: TDeviceConnectResult.dcrError,
          ResultMessage: "Message couldn't be sended.",
        } as DeviceConnectorDataRequest);
      }
    }
    return Promise.reject<DeviceConnectorDataRequest>({
      ResultType: TDeviceConnectResult.dcrError,
      ResultMessage: "Connection was not connected",
    } as DeviceConnectorDataRequest);
  }

  private replaceFile(message: any): any {
    if (message && message.Json) {
      this.files.forEach((url: string, key: string) => {
        if (message.Json.indexOf(key) >= 0) {
          message.Json = message.Json.replace(key, url);
        }
      });
    }
    this.files.clear();

    return JSON.stringify(message);
  }
}
